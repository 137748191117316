var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"white-background bg-white mb-5 mx-3"},[(_vm.showText)?_c('button',{staticClass:"btn btn-default btn-organism",on:{"click":function($event){$event.preventDefault();return _vm.switchView()}}},[_vm._v(" View species tree ")]):_vm._e(),(!_vm.showText)?_c('button',{staticClass:"btn btn-default btn-organism",on:{"click":function($event){$event.preventDefault();return _vm.switchView()}}},[_vm._v(" Switch to see full list of species in plain text ")]):_vm._e(),_c('a',{staticClass:"btn btn-outline btn-organism a-link"},[_c('router-link',{staticClass:"text-magenta",attrs:{"to":"/grafting"}},[_vm._v("Not seeing your species?")])],1),(_vm.showText)?_c('div',{staticClass:"scroll-div bg-white my-2 px-5"},[_c('p',{staticClass:"text-lg mr-5 pr-5"},[_vm._v(" Protein sequences from these plant species are included in the current PhyloGenes release (version 4.1): ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('p',_vm._l((_vm.plantSpecies.slice(
                0,
                (_vm.plantSpecies.length + 1) / 2
              )),function(data){return _c('a',{attrs:{"href":'https://conf.arabidopsis.org/display/PHGSUP/' +
                data.sciName.replace(' ', '+')}},[_c('i',[_vm._v(_vm._s(data.sciName))]),_vm._v("  "),(data.cmnName)?[_vm._v("("+_vm._s(data.cmnName)+")")]:_vm._e(),_c('br')],2)}),0)]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('p',_vm._l((_vm.plantSpecies.slice(
                (_vm.plantSpecies.length + 1) / 2
              )),function(data){return _c('a',{attrs:{"href":'https://conf.arabidopsis.org/display/PHGSUP/' +
                data.sciName.replace(' ', '+')}},[_c('i',[_vm._v(_vm._s(data.sciName))]),_vm._v("  "),(data.cmnName)?[_vm._v("("+_vm._s(data.cmnName)+")")]:_vm._e(),_c('br')],2)}),0)])]),_c('p',{staticClass:"text-lg"},[_vm._v(" Protein sequences from the following non-plant model organisms are include to provide functional information that can be useful for when no experimental plant data is available: ")]),_c('p',[_vm._l((_vm.nonPlantSpecies),function(data){return [_c('i',[_vm._v(_vm._s(data.sciName))]),_vm._v("  "),(data.cmnName)?[_vm._v("("+_vm._s(data.cmnName)+")")]:_vm._e(),_c('br')]})],2)]):_vm._e(),(!_vm.showText)?_c('div',{staticClass:"scroll-div bg-white mt-lg d-flex align-items-center"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../../src/assets/img/species-tree-Panther17.png"),"alt":"speciestree"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }