export const TREE_GET_DATA = 'tree/GetData'
export const TREE_IS_ERROR = 'tree/IsError'
export const TREE_IS_LOADING = 'tree/IsLoading'
export const TREE_GET_SEARCH_TEXT = 'tree/getSearchText'
export const TREE_GET_FILTERS = 'tree/getFilters'
export const TREE_DATA_GET_FILTERS = 'tree/getJsonFilters'
export const TREE_DATA_GET_JSON = 'tree/getDataInJson'

// actions
export const TREE_ACTION_SET_SEARCH = 'tree/setSearchText'
export const TREE_ACTION_RESET_SEARCH = 'tree/resetSearchText'
export const TREE_ACTION_RESET_FILTER = 'tree/resetFilter'
export const TREE_ACTION_SET_FILTER = 'tree/setFilters'
export const TREE_ACTION_DO_SEARCH = 'tree/doSearch'
export const TREE_ACTION_PAGINATE = 'tree/paginate'
