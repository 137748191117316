<template>
  <div class="surveyText">
    Make PhyloGenes more useful! In less than 5 minutes, you can guide PhyloGenes' future.
    <br />
    <br />Please click on a link below and take the short survey after your visit today:
    <a
      href="https://www.surveymonkey.com/r/9ZT25V3"
      target="_blank"
    >https://www.surveymonkey.com/r/9ZT25V3</a>
    <br />
    <br />For users in China who can't access SurveyMonkey:
    <a
      href="https://www.wenjuan.com/s/UbiuIfJ/"
      target="_blank"
    >https://www.wenjuan.com/s/UbiuIfJ/</a>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.surveyText {
  word-wrap: break-word;
  font-size: 20px;
}
</style>