<template>
  <div class="p-5 m-5 text-muted">
    <span><i class="fas fa-5x fa-coffee"></i></span>
    <span class="h1">Just relax...</span>
    <div class="h2 mt-3">We are building something awesome here.</div>
  </div>
</template>

<script>
export default {
  name: 'Relax',
}
</script>

<style scoped></style>
