import { render, staticRenderFns } from "./StraightLink.vue?vue&type=template&id=dd546758&scoped=true"
import script from "./StraightLink.vue?vue&type=script&lang=js"
export * from "./StraightLink.vue?vue&type=script&lang=js"
import style0 from "./StraightLink.vue?vue&type=style&index=0&id=dd546758&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd546758",
  null
  
)

export default component.exports