<template>
  <div>
    <div class="elevation-0 px-5 bg-pggrey">
      <div class="container-fluid px-3" style="max-width: 1600px;">
        <p class="lead text-dark py-3 my-0 text-bold" style="text: bold;">
          <b
            >PhyloGenes displays pre-computed phylogenetic trees of gene
            families alongside experimental gene function data to facilitate
            inference of unknown gene function in plants.</b
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped></style>
